





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class InsCmsCat8 extends Vue {
  contentList: object[] = []; // cms内容列表
  currentPage: number = 1; // 当前页
  pageSize: number = 9; // 每页显示条目个数
  totalRecord: number = 0;// 总条目数
  heightArr: number[]=[];
  loadImageCount:number = 0;
  imageCoverCount: number = 0;

  // 活动区当前页
  getCurrentPage () {
    this.currentPage = this.$route.query.page ? Number(this.$route.query.page) : 1;
    this.getSubCatContents();
  }
  // 获取文章
  getSubCatContents () {
    this.$Api.cms
      .getSubCatContents({ CatId: this.id, Page: this.currentPage, PageSize: this.pageSize, IsMobile: this.isMobile })
      .then(result => {
        result.Data.forEach(item => {
          item['ContentDateTime'] = item['ContentDateTime'].substring(0, item['ContentDateTime'].indexOf(' '));
        });
        this.contentList = result.Data;
        this.totalRecord = result.TotalRecord;
        console.log(result, ' 获取content列表');
        // 计算返回图片的数量
        this.contentList.forEach((item) => {
          if (item['Cover']) {
            this.imageCoverCount += 1;
          };
        });
      });
  }
  loadImage () {
    this.loadImageCount += 1;
  }
  waterfall () {
    const lis = $('.content-list li');
    const liW = 410;
    $.each(lis, (index, item) => {
      let liH = Number($(item).outerHeight()) + 30; // 30是margin-bottom
      if (index < 3) {
        this.heightArr[index] = liH;
      } else {
        let minLiH = Math.min.apply(Math.min, this.heightArr);
        let minLiIndex = $.inArray(minLiH, this.heightArr);
        $(item).css({
          position: 'absolute',
          top: minLiH + 'px',
          left: minLiIndex * liW + 'px'
        });
        this.heightArr[minLiIndex] = minLiH + liH;
      }
      let maxLiH = Math.max.apply(Math.max, this.heightArr);
      let maxLiIndex = $.inArray(maxLiH, this.heightArr);
      $('.content-list').height(this.heightArr[maxLiIndex]);
    });
  }
  get pagerCount () {
    return this.isMobile ? 5 : 7;
  }
  get id () {
    console.log(this.$route.params.id, '当前id');
    return this.$route.params.id;
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }

  handleCurrentChange (val) {
    this.currentPage = val;
    console.log(`当前页: ${val}`);
    this.getSubCatContents();
    // 返回顶部
    this.isMobile ? $(window).scrollTop(this.$refs['conbox']['offsetTop'] - 200) : $(window).scrollTop(this.$refs['conbox']['offsetTop'] - 300);
  }

  created () {
    this.getCurrentPage();
  }

  toUrl (n) {
    if (n.Url && n.IsOpenWindows) {
      window.open(n.Url);
    } else if (n.Url && !n.IsOpenWindows) {
      window.location.href = n.Url;
    } else {
      this.$router.push('/cms/content/' + n.Id);
    }
  }
  @Watch('id', { deep: true })
  onKeyChange () {
    this.contentList = [];
    this.getSubCatContents();
  }
  @Watch('currentPage', { deep: true })
  onCurrentPageChange () {
    this.$router.push(`?page=${this.currentPage}`);
  }
  @Watch('loadImageCount', { deep: true })
  onImgLoadCountChange () {
    // 当下载图片数量和后台返回图片数量相等时，可执行瀑布流排布
    if (this.loadImageCount === this.imageCoverCount && !this.isMobile) {
      this.waterfall();
    }
  }
}
